<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-toolbar
          dark
          color="red"
          dense
        >
          <h1>TIPOS DE PROBLEMA</h1>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card elevation="10">
          <v-row justify="center">
            <v-card-title>
              <h3>Tipos de problemas creados</h3>
            </v-card-title>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                heigth="350"
                dense
                :headers="headers"
                :items="tipoprob"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="editar(item)">
                    <td>{{ item.idtipoprob }}</td>
                    <td>{{ item.nomtipoprob }}</td>
                    <td>
                      <v-simple-checkbox
                        v-model="item.activo"
                        color="blue"
                        disabled
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="6">
        <tipoprob />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tipoprob from '@/views/crm/tipos/cattipoprob/tipoprob.vue'
export default {
  components:{
    tipoprob,
  },
  data () {
    return {
      nomtipoprob:'',
      search: '',
      tipoprob: [],
      headers: [
        { text: 'Id', value: 'idtipoprob' },
        { text: 'Problema', value: 'nomtipoprob' },
        { text: 'Estatus', value: 'activo'}
      ],
    }
  },
  created(){
    this.consultar()
  },
  methods: {
    consultar(){
      // limpiar
      this.tipoprob= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)
      this.loading = true

      this.$http.get("v2/tipoprob.list" ).then(response=>{
        if (response.data.error===null){
          this.loading = false
          this.tipoprob = response.data.result

          this.tipoprob.forEach( element => {
            if(element.activo == '1'){
              element.activo = true
            }
            else {
              element.activo = false
            }
          })
          console.log("tipoprob", this.tipoprob)
        }
      }).catch(error=>{
        	console.log(error)
      })
    },
    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'tipoprob', params: {tipoprob: item}})
    },

  }
}
</script>