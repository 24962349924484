<template>
  <v-card>
    <v-toolbar
      color="blue-grey lighten-1"
      dense
    >
      <v-toolbar-title class="white--text">
        CONFIGURACION
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        text
        dark
        @click="mostraract"
      >
        TIPO ACTIVIDAD
      </v-btn>
      <v-btn
        text
        dark
        @click="mostrarprob"
      >
        TIPO PROBLEMA
      </v-btn>
      <v-btn
        text
        dark
        @click="mostraroport"
      >
        TIPO OPORTUNIDAD
      </v-btn>
    </v-toolbar>
    <cattipoact v-if="vertipoact" />
    <cattipoprob v-if="vertipoprob" />
    <cattipooport v-if="vertipooport" />
  </v-card>
</template>

<script>
import cattipooport from '@/views/crm/tipos/cattipooport/cattipooport.vue'
import cattipoact from '@/views/crm/tipos/cattipoact/cattipoact.vue'
import cattipoprob from '@/views/crm/tipos/cattipoprob/cattipoprob.vue'
export default {
  components:{
    cattipooport,
    cattipoact,
    cattipoprob,
  },
  data: () => ({
    vertipoact: false,
    vertipoprob: false,
    vertipooport: false,
    type: 'day'
  }),
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
      
  },
  
  methods: {
    mostraract(){
      this.vertipoact  = true
      this.vertipoprob = false
      this.vertipooport = false
    },
    mostrarprob(){
      this.vertipoprob = true
      this.vertipoact   = false
      this.vertipooport = false
    },
    mostraroport(){
      this.vertipooport = true
      this.vertipoprob     = false
      this.vertipoact      = false
    },
  },
}
</script>