<template>
  <v-container>
    <v-card elevation="10">
      <v-row justify="center">
        <v-toolbar-title>
          <h4 class="ma-4 green--text">
            {{ titulo }}
          </h4>
        </v-toolbar-title>
      </v-row>

      <v-row justify="center">
        <v-col cols="7">
          <v-text-field
            v-model="nomtipooport"
            prepend-icon="mdi-pencil"
            label="Nueva oportunidad"
          />
        </v-col>
        <v-col cols="4">
          <v-switch
            v-model="activo"
            flat
            color="green"
            label="Estatus"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8" />
        <v-col>
          <v-btn
            align="right"
            color="success"
            dark
            small
            class="ma-1"
            @click="agregar"
          >
            <v-icon>mdi-check-circle</v-icon>Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default{
  data(){
    return{
      switch1: false,
      snackbar: false,
		    nomtipooport:'',
		    idtipooport:'',
      activo: false,
      titulo:'',
		    nModo: 1
    }
  },
	
  computed:{
	    validaInfo(){
	      return  this.nomtipooport   !== ''
	    },
	  },

  created(){
    console.log('router', this.$route.params.tipooport )

    let ruta = this.$route.params.tipooport

	    //MODO EDITAR
	    if ( ruta !== undefined ){
	        this.nModo=2
	        this.readonly= true

	        this.CargarInfo(this.$route.params.tipooport)
	        this.modo = 'Editar'
      this.titulo = 'Editar tipo de oportunidad'

	     } else {
	     		console.log("nuevo")
	        //VALORES INICIALES ALTA
	        this.nModo = 1
	        this.modo  = 'Nuevo'
      this.titulo = 'Agrega un nuevo tipo de oportunidad'
	    }

  },

  methods:{
    ...mapActions("snackbar", ["showSnack"]),


    CargarInfo(tipooport){
      console.log("tipooport", tipooport)
      this.nomtipooport = tipooport.nomtipooport
      this.activo       = tipooport.activo
      this.idtipooport = tipooport.idtipooport
    },

    agregar(){
				
      //VALIDAR INFORMACION
		    if(!this.validaInfo){
		    	 this.showSnack({
          text: "Debes registrar el asunto, los temas y la fecha",
          color: "orange",
          timeout: 3000,
        });

		    	console.log("FALLA EN LLENAR")
		        // this.snackbar = true ; this.color="orange"
		        // this.text = "Faltan llenar Datos. Nombre, email, telefono"
		      return
		    }
      //Convertir buleano a numero
      let activo2 = +this.activo
      //Converir numero a string
      let activo3 = activo2.toString()
      var payload = {
        nomtipooport: this.nomtipooport,
        activo: activo3
      }
      console.log("payload", payload)

	     //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http.post('v2/insert.tipooport', payload).then(response =>{
			        this.showSnack({
		            text: "Tipo de oportunidad agregada correctamente",
		            color: "success",
		            timeout: 2000,
		          });

			        this.limpiartipooport()
	            // setTimeout (() => this.$router.push({name:'catclientes'}),2000);
        }).catch(error=>{
            	// this.snackbar = true ;
	            // this.color="error"
	            // this.text = "Problemas para grabar el cliente"
	            console.log(error)
         			this.showSnack({
		            text: "Problema para crear tipo de oportunidad "+ error,
		            color: "error",
		            timeout: 2000,
		          });
     
        })
        
      } else {
          
        // EDICION DE UNA tipooport.
        this.$http.put('v2/update.tipooport/' + this.idtipooport, payload).then(response =>{
          this.showSnack({
		            text: "Tipo de oportunidad actualizada correctamente.",
		            color: "green",
		            timeout: 2000,
		          });
          setTimeout (() => this.$router.push({name:'configuracion'}),2000);

        }).catch(error=>{
            	this.showSnack({
		            text: "Problema para actualizar tipo de oportunidad"+ error,
		            color: "error",
		            timeout: 2000,
		          });
          console.log(error)
        })

      }


    },
    limpiartipooport(){
      this.nomtipooport = ''
    },
   				
   	  regresar(){
      console.log("ruta", this.$route.name)
    },
  }
}
</script>
