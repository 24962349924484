<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <v-toolbar
          dark
          color="green darken-1"
          dense
        >
          <h1>TIPOS DE OPORTUNIDAD</h1>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card elevation="10">
          <v-row justify="center">
            <v-card-title>
              <h3>Tipos de oportunidad creados</h3>
            </v-card-title>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                heigth="350"
                dense
                :headers="headers"
                :items="tipooport"
                :search="search"
              >
                <template v-slot:item="{ item }">
                  <tr @dblclick="editar(item)">
                    <td>{{ item.idtipooport }}</td>
                    <td>{{ item.nomtipooport }}</td>
                    <td>
                      <v-simple-checkbox
                        v-model="item.activo"
                        color="blue"
                        disabled
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="6">
        <tipooport />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tipooport from '@/views/crm/tipos/cattipooport/tipooport.vue'
export default {
  components:{
    tipooport,
  },
  data () {
    return {
      nomtipoact:'',
      search: '',
      tipooport: [],
      headers: [
        { text: 'Id', value: 'idtipooport' },
        { text: 'Nombre de la Actividad', value: 'nomtipooport' },
        { text: 'Estatus', value: 'activo'}
      ],
    }
  },
  created(){
    this.consultar()
  },
  methods: {
    consultar(){
      // limpiar
      this.tipooport= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)
      this.loading = true

      this.$http.get("v2/tipooport.list" ).then(response=>{
        if (response.data.error===null){
          this.loading = false
          this.tipooport = response.data.result

          this.tipooport.forEach( element => {
            if(element.activo == '1'){
              element.activo = true
            }
            else {
              element.activo = false
            }
          })
          console.log("tipooport", this.tipooport)
        }
      }).catch(error=>{
        	console.log(error)
      })
    },
    editar(item){
      console.log("Renglonitem", item)
      this.$router.push({name:'tipooport', params: {tipooport: item}})
    },

  }
}
</script>